<!-- 0030df详情 -->
<template>
<div v-if="queryMark" class="invoiceDetail">
  <div class="vehicleInfo common-block">
    <div class="common-t">
      <div class="common-t-l">{{$t('车辆信息')}}</div>
    </div>
    <div class="vehicle-c">
      <div class="item vehicle-t-r">
        <div class="vehicle">{{ `${detail.carOrderMaterial?.seriesName} ${detail.carOrderMaterial?.modelName}` }}</div>
        <!-- <div v-if="detail.totalAmount" class="amount"><span content="¥"></span>{{ `${formatRMB(detail.totalAmount)}` }}</div> -->
      </div>
      <div class="item half">
        <div class="common-label-t">{{$t('车身颜色：')}}</div>
        <div class="common-label-c">{{ detail.carOrderMaterial?.colourName }}</div>
      </div>
      <div class="item half">
        <div class="common-label-t">{{$t('内饰颜色：')}}</div>
        <div class="common-label-c">{{ detail.carOrderMaterial?.interiorName }}</div>
      </div>
      <!-- <div class="item">
        <div class="common-label-t">选装：</div>
        <div class="common-label-c options">
          <div v-for="(optionsItem, index) in (detail.carOptionsList || [])" :key="index" class="options-t">{{ `${index+1}、${optionsItem.optionsName || ''}` }}</div>
        </div>
      </div> -->
      <div class="item">
        <div class="common-label-t">VIN：</div>
        <div class="common-label-c">{{ detail.vin }}</div>
      </div>
    </div>
  </div>
  <div class="invoiceInfo common-block">
    <div class="common-t">
      <div class="common-t-l">{{$t('发票')}}</div>
    </div>
    <div v-if="imgList.length > 0" class="invoiceImage">
      <van-uploader :file-list="imgList" :deletable="false"/>
    </div>
  </div>
  <div class="common-footer">
    <van-button class="btn submit" @click="uploadInvoice">{{$t('重新上传发票')}}</van-button>
  </div>
  <van-dialog
    v-model="showDialog"
    :title="$t('上传发票')" 
    show-cancel-button 
    :cancel-button-text="$t('取消')" 
    :confirm-button-text="$t('上传')"
    :close-on-click-overlay="true"
    :before-close="beforeClose">
    <van-form ref="dialogRef" style="display: flex; justify-content: center; padding: 20px;">
      <van-uploader
        capture="camera"
        accept="image/*"
        :preview-options="{closeable: true}"
        :before-read="beforeRead"
        :after-read="(file) => afterRead(file)"
        :file-list="fileList"
        :before-delete="(file) => beforeDelete(file)"
        :max-count="1"/>
    </van-form>
  </van-dialog>
</div>
</template>
<script>
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { v4 as uuidv4 } from 'uuid'
import { uploadFile } from '@/utils/cos'
export default {
  name: 'InvoiceDetail',
  data() {
    return {
      queryMark: false,
      detail: {},
      imgList: [],
      fileList: [],
      showDialog: false,
      params: {

      }
    }
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    async getDetail() {
      loading.showLoading()
      const res = await deliveryServices.deliveryDetail({ id: this.$route.query.id })
      this.fileList = []
      this.imgList = []
      res.invoice?.einvoiceUrl && this.imgList.push({
        url: res.invoice.einvoiceUrl
      })
      this.queryMark = true
      loading.hideLoading()
      this.detail = res || {}
    },
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    beforeClose(action, done){
      var that = this
      this.$refs.dialogRef.validate().then(async() => {
        if (action === 'confirm') {
          if (that.fileList.length === 0) {
            this.$toast(this.$t('请上传发票图片'))
            done(false)
            return false
          }
          const params = {
            invoiceUrl: that.fileList[0]?.cosUrl,
            orderId: this.$route.query.id
          }
          loading.showLoading()
          const res = await deliveryServices.uploadInvoice(params)
          loading.hideLoading
          const { code, msg } = res
          if (code === 0) {
            this.$toast(this.$t('上传成功'))
            this.getDetail()
            done()
          } else {
            done(false)
            this.$toast(msg)
          }
        } else {
          done()
        }
      })
    },
    // 上传发票
    uploadInvoice() {
      this.showDialog = true
    }
  }
}
</script>
<style lang="less" scoped>
.invoiceDetail{
  background: #f5f5f5;
  padding: 16px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: 96px;
  .common-block{
    padding: 0 12px 12px;
    &:not(:last-of-type){
      margin-bottom: 16px;
    }
  }
  .vehicle-c{
    display: flex;
    flex-wrap: wrap;
    // border-top: 1px solid rgba(13,23,26,0.05);
    margin-top: 8px;
    .item{
      display: flex;
      width: 100%;
      &.vehicle-t-r{
        display: flex;
        justify-content: space-between;
        color: #0D171A;
        line-height: 16px;
        font-size: 13px;
        .vehicle{
          font-size: 16px;
        }
        .amount{
          color: #E4002C;
        }
      }
      &:not(:last-of-type){
        margin-bottom: 8px;
      }
      &.half{
        width: 50%;
      }
      .item-d{
        width: 85px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: rgba(13,23,26,0.45);
      }
    }
  }
  .invoiceImage{
    width: 100%;
    height: 300px;
    /deep/ .van-uploader{
      width: 100%;
      height: 100%;
      overflow: hidden;
      .van-uploader__wrapper{
        height: 100%;
      }
      .van-uploader__preview{
        width: 100%;
        margin: 0;
        .van-uploader__preview-image{
          width: 100%;
          height: 100%;
          img{
            object-fit: scale-down !important;
          }
        }
      }
      .van-uploader__upload{
        display: none;
        
      }
    }
  }
}
</style>